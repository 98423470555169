import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/pages/login/login.vue'
import aboutUs from "../pages/login/AboutUs.vue";
import privacyPolicy from "../pages/login/PrivacyPolicy.vue";
import returnPolicy from "../pages/login/ReturnPolicy.vue";
import termsOfService from "../pages/login/TermsOfService.vue";
import signUp from "../pages/login/SignUp.vue";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes:[
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/login/aboutus',
      name: 'aboutUs',
      component: aboutUs
    },
    {
      path: '/login/privacypolicy',
      name: 'privacyPolicy',
      component: privacyPolicy
    },
    {
      path: '/login/returnpolicy',
      name: 'returnPolicy',
      component: returnPolicy
    },
    {
      path: '/login/termsofservice',
      name: 'termsOfService',
      component: termsOfService
    },
    {
      path: '/login/signUp',
      name: 'signUp',
      component: signUp
    },
  ]
})

export default router