// const API_ROOT  = _ENV_.SOURCE.API_ROOT
// 废弃， 登录 流程使用单点登录，没有用这个登录了
const LOGIN_URL  = _ENV_.SOURCE.LOG_BASE
const QUICKORDER = _ENV_.SOURCE.QUICKORDER_URL
// 登录
export const loginUrl = LOGIN_URL + 'oauth/token?grant_type=password'
export const signUpInfoUrl = QUICKORDER + '/customer/signUpInfo'
export const getCountryUrl = QUICKORDER + '/address/getCountry/'
export const getStateByCountryCodeUrl = QUICKORDER + '/address/getStateByCountryCode/'
export const getCityByStateCodeUrl = QUICKORDER + '/address/getCityByStateCode/'
export const getCityAndStateByPostalCodeUrl = QUICKORDER + '/address/getCityAndStateByPostalCode/'
