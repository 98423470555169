<template>
  <v-app>
    <div id="bgi" :style="checkPhone ? 'background-size: 100%' : ''">
      <v-sheet elevation="16" style="position: absolute;top: 45%; left: 50%; transform: translate(-50%,-50%);" :style="checkPhone ? 'width: 70%;display: flex' : 'width: 90%;'">
        <div :style="checkPhone ? 'width: 73%' : ''">
          <v-img
              cover
              :src="bannerUrl"
          ></v-img>
        </div>
        <div :style="checkPhone ? 'width: 27%' : ''">
          <v-card height="100%" elevation="0" style="color: #39aaba">
            <v-card-title style="height: 30%">
              <div style="color: #39aaba;font-size: 1.75em;font-weight: bold;margin: 0 auto;">
                LOGIN
              </div>
            </v-card-title>
            <v-card-text style="height: 70%;">
              <v-form ref="forms" style="height: 100%;">
                <div style="height: 26%;">
                  <v-text-field
                      underlined=false
                      append-icon="mdi-account"
                      v-model="username"
                      autocomplete="new-password"
                      label="E-Mail"
                      :rules="[notEmpty]"
                      color="#39aaba"
                      style="width: 75%;margin: 0 auto;"
                      class="text-field-color"
                  >
                  </v-text-field>
                </div>
                <div style="height: 26%;">
                  <v-text-field
                      underlined=false
                      append-icon="mdi-magnify"
                      v-model="password"
                      autocomplete="new-password"
                      type="password"
                      label="Password"
                      :rules="[notEmpty]"
                      color="#39aaba"
                      style="width: 75%;margin: 0 auto;"
                      class="text-field-color"
                  >
                  </v-text-field>
                </div>
                <div class="text-center" :style="checkPhone ? 'height: 22%' : 'height: 60px'">
                  <v-btn class="text-none" height="67%" rounded color="#39aaba" @click="handleLogin" style="color: white;width: 70%;font-size: 1.375em;">Login</v-btn>
                </div>
                <div class="text-center">
                  <a @click="toSignUp" style="color: #39aaba;font-size: 1.5em;line-height: 100%">Sign Up</a>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <s-message type="error" v-model="showMessage">{{
              message || ""
            }}</s-message>
        </div>
      </v-sheet>
      <!--      <div style="text-align: center; margin-top: 3%">-->
      <!--      <span style="font-size: 2.75em;font-weight: bold;">-->
      <!--        SST Parts-->
      <!--      </span>-->
      <!--        <span style="font-size: 1.75em">Wholesale</span>-->
      <!--      </div>-->
      <!--      <div style="text-align: center;margin-top: 20px;font-size: 1.25em;">-->
      <!--        World's 1st real time wholesale platform for cellphone &amp; tablet parts!-->
      <!--      </div>-->

      <div style="position: absolute; left: 3%; bottom: 2%">
        <span  class="s-primary pointer mr-3" @click="showPolicy('aboutUs')">About Us</span>
        <span  class="s-primary pointer mr-3" @click="showPolicy('termsOfService',)">Terms of Service</span>
        <span  class="s-primary pointer mr-3" @click="showPolicy('privacyPolicy')">Privacy Policy</span>
        <span  class="s-primary pointer mr-3" @click="showPolicy('returnPolicy')">Return Policy</span>
      </div>

      <s-dialog
          v-model="showDialog"
          v-bind="dialogAttrs"
          @close="closeDialog"
          :hide-actions="true"
          :hide-confirm="true"
      >
        <div class="pa-2">Call / Text / WhatsApp: +1 650 796 9090</div>
        <div class="pa-2">Email: <a href="mailto:Neo@SstParts.com">Neo@SstParts.com</a></div>
      </s-dialog>
    </div>

  </v-app>
</template>
<style scoped>
#bgi {
  width: 100%;
  height: 100%;
  background: url("./background.jpg") center center no-repeat;
  position: fixed;
}

/*text-field 未获得焦点时边框颜色*/
.text-field-color.theme--light.v-text-field--outlined /deep/ {
  color: #39aaba;
}

/*text-field 获得焦点前hover边框颜色*/
.text-field-color.theme--light.v-text-field:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled):hover /deep/ {
  color: #39aaba;
}

/*text-field 获得焦点后append图标颜色*/
.text-field-color /deep/ i {
  color: #39aaba;
}

.text-field-color /deep/ label {
  color: #39aaba;
}

.text-field-color.v-text-field >>> .v-input__slot::before  { border-color: #39aaba !important; }
</style>
<script>
import rules from "../mixins/rules";
import SMessage from "@/components/Message.vue";
import { loginUrl } from "@/api";
import http from "@/http";
import qs from "qs";
import CryptoJS from "crypto-js";
import SDialog from "../../components/Dialog.vue";
import bannerUrl from './main-banner.png'
export default {
  name: "login",
  mixins: [rules],
  components: {
    SDialog,
    SMessage,
  },
  data() {
    return {
      username: "",
      password: "",
      message: "",
      showMessage: false,
      showDialog: false,
      dialogAttrs: {},
      bannerUrl,
    };
  },
  computed: {
    checkPhone() {
      return window.matchMedia("(min-width: 800px)").matches;
    },

  },
  methods: {
    /**
     *加密处理
     */
    encryption(params) {
      let { data, type, param, key } = params;
      const result = JSON.parse(JSON.stringify(data));
      if (type === "Base64") {
        param.forEach((ele) => {
          result[ele] = btoa(result[ele]);
        });
      } else {
        param.forEach((ele) => {
          var data = result[ele];
          key = CryptoJS.enc.Latin1.parse(key);
          var iv = key;
          // 加密
          var encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding,
          });
          result[ele] = encrypted.toString();
        });
      }
      return result;
    },
    async handleLogin() {
      if (!this.$refs.forms.validate()) return;
      // const $http = this.$http
      const $http = await http();
      let userInfo = { username: this.username, password: this.password };

      const user = this.$env === 'local' ? userInfo :
          this.encryption({
            data: userInfo,
            key: "quotationcloudxx",
            param: ["password"],
          });
      let dataObj = qs.stringify({
        username: user.username,
        password: user.password,
        userType:'QOC'
      });
      const res = await $http.post(loginUrl, dataObj, {
        config: {
          headers: {
            isToken: false,
            Authorization: "Basic cXVvdGF0aW9uOnF1b3RhdGlvbg==",
          },
        },
      });

      if (res && res.code === "200") {
        // 全局设定用户信息
        // console.log(res, res.data.user_Info, 108);
        this.$store.dispatch("app/setUserInfo", res.data.user_info);
        const query = this.$route.query;

        if (query.returnUrl) {
          this.$router.replace(query.returnUrl);
        } else {
          this.$router.replace("/");
        }
      }
      // .then((res) => {
      //   debugger
      //   if (res && res.code === "200") {
      //     // 全局设定用户信息
      //     // console.log(res, res.data.user_Info, 108);
      //     this.$store.dispatch("_app/setUserInfo", res.data.user_info);
      //     const query = this.$route.query;
      //     if (query) {
      //       debugger
      //       this.$router.replace(query.returnUrl);
      //     } else {
      //       debugger
      //       this.$router.replace("/");
      //     }
      //   }
      // });
    },
    showPolicy(module) {
      this.$router.push({ name: module });
    },
    toSignUp() {
      this.$router.push({ name: "signUp" });
    },
    showContact() {
      this.dialogAttrs = {
        title: "Instant free account access:",
        "max-width": "400px",
        "component-attrs": {},
      };
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>