<template>
  <v-app>
    <div id="bgi">
      <div style="width: 56%; min-width: 1050px; margin: 0 auto; font-weight: 500;padding: 0 30px">
        <div class="text-center ma-3" style="color: #39aaba; font-size: 1.5em; font-weight: 600;">
          <div>
            <img class="pointer" @click="backToLogin" :src="logoUrl" style="width: 200px;">
          </div>
          Create an Account
        </div>
        <div>
          <v-form ref="forms" style="height: 100%;">
            <span style="color: #39aaba;">Organization Information</span>
            <v-container>
              <span style="color: #FF5252;">* </span><span style="color: grey">Business Name</span>
              <v-text-field
                  v-model="forms.company"
                  :rules="[notEmpty]"
                  clearable
                  outlined
                  dense
                  style="width: 400px;"
                  hide-details="true"
                  class="mb-2"
              ></v-text-field>
              <span style="color: #FF5252;">* </span><span style="color: grey">Industry</span>
              <v-radio-group v-model="forms.industry" row :rules="[notEmpty]" hide-details="true" class="mb-2" style="margin-top: 0">
                <v-radio label="Repair Service (Shop)" value="Repair Service (Shop)"></v-radio>
                <v-radio label="Drop Shiper" value="Drop Shiper"></v-radio>
                <v-radio label="Repair Center / Refurbisher" value="Repair Center / Refurbisher"></v-radio>
                <v-radio label="Parts Reseller" value="Parts Reseller"></v-radio>
                <v-radio label="Others" value="Others"></v-radio>
              </v-radio-group>
              <span style="color: #FF5252;">* </span><span style="color: grey">How did you hear about us?</span>
              <v-radio-group v-model="forms.hearFrom" row :rules="[notEmpty]" hide-details="true" class="mb-2" style="margin-top: 0">
                <v-radio label="Google" value="Google"></v-radio>
                <v-radio label="Referred by friends" value="Referred by friends"></v-radio>
                <v-radio label="Forum" value="Forum"></v-radio>
                <v-radio label="Youtube" value="Youtube"></v-radio>
                <v-radio label="Facebook" value="Facebook"></v-radio>
                <v-radio label="Instagram" value="Instagram"></v-radio>
                <v-radio label="Tiktok" value="Tiktok"></v-radio>
                <v-radio label="Others" value="Others"></v-radio>
              </v-radio-group>
              <span style="color: #FF5252;">* </span><span
                style="color: grey">Estimated Monthly Order&nbsp;(From All Sources)</span>
              <v-radio-group v-model="forms.monthlyOrderAmount" row :rules="[notEmpty]" hide-details="true" class="mb-2" style="margin-top: 0">
                <v-radio label="Up to $3,000" value="Up to $3,000"></v-radio>
                <v-radio label="$3,001 to $5,000" value="$3,001 to $5,000"></v-radio>
                <v-radio label="$5,001 to $10,000" value="$5,001 to $10,000"></v-radio>
                <v-radio label="$10,001 to $30,000" value="$10,001 to $30,000"></v-radio>
                <v-radio label="More than $30,001" value="More than $30,001"></v-radio>
              </v-radio-group>
              <div style="display: flex">
                <div style="display: block; width: 22%; margin-right: 2%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Country</span>
                  <v-select
                      v-model="forms.country"
                      :items="countries"
                      :rules="[notEmpty]"
                      @change="toChangeCountry"
                      item-text="countryName"
                      item-value="countryCode"
                      dense
                      outlined
                      hide-details="true"
                      class="mb-2"
                  >
                  </v-select>
                </div>
                <div style="display: block; width: 22%; margin-right: 2%">
                  <span v-if="forms.country === 'US' || forms.country === 'CA'" style="color: #FF5252;">* </span><span style="color: grey">Postal / Zip Code</span>
                  <v-text-field
                      v-model="forms.zip"
                      :rules="[notEmptyIfUs]"
                      @change="toChangeZipcode"
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
                <div style="display: block; width: 22%; margin-right: 2%">
                  <span style="color: grey">City</span>
                  <v-combobox
                      v-model="forms.city"
                      :items="cities"
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-combobox>
                </div>
                <div style="display: block; width: 22%">
                  <span style="color: grey">State</span>
                  <v-select
                      v-model="forms.state"
                      :items="states"
                      @change="toChangeState"
                      item-text="stateName"
                      item-value="stateCode"
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  >
                  </v-select>
                </div>
              </div>
              <span style="color: grey">Website</span>
              <v-text-field
                  style="width: 94%"
                  v-model="forms.website"
                  clearable
                  outlined
                  dense
                  hide-details="true"
                  class="mb-2"
              ></v-text-field>
              <span style="color: grey">Anything else you would like us to know?</span>
              <v-textarea
                  style="width: 94%"
                  v-model="forms.otherInfo"
                  rows="1"
                  no-resize
                  clearable
                  outlined
                  hide-details="true"
                  class="mb-2"
              ></v-textarea>
            </v-container>

            <span style="color: #39aaba;">User Information</span>
            <v-container>
              <div style="display: flex">
                <div style="display: block; width: 30%; margin-right: 2%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">First Name</span>
                  <v-text-field
                      v-model="forms.firstName"
                      :rules="[notEmpty]"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
                <div style="display: block; width: 30%; margin-right: 2%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Last Name</span>
                  <v-text-field
                      v-model="forms.lastName"
                      :rules="[notEmpty]"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
                <div style="display: block; width: 30%">
                  <span style="color: grey">Job Title</span>
                  <v-text-field
                      v-model="forms.jobTitle"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
              </div>
              <div style="display: flex">
                <div style="display: block; width: 46%; margin-right: 2%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Email&nbsp;(this email will be your login name)</span>
                  <v-text-field
                      v-model="forms.email"
                      :rules="[notEmpty, email]"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
                <div style="display: block; width: 46%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Telephone</span>
                  <v-text-field
                      v-model="forms.phone"
                      :rules="[notEmpty]"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
              </div>
              <div style="display: flex">
                <div style="display: block; width: 32%; margin-right: 2%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Password</span>
                  <v-text-field
                      v-model="forms.password"
                      :rules="[notEmpty]"
                      type="password"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
                <div style="display: block; width: 32%">
                  <span style="color: #FF5252;">* </span><span style="color: grey">Confirm Password</span>
                  <v-text-field
                      v-model="forms.confirmPassword"
                      :rules="[notEmpty, confirmPass]"
                      type="password"
                      clearable
                      outlined
                      dense
                      hide-details="true"
                      class="mb-2"
                  ></v-text-field>
                </div>
              </div>
              <v-checkbox v-model="agreedToPolicy" :rules="[agreed]">
                <template #label>
                  By creating an account, you agree to SstParts'&nbsp;<a @click="toPrivacyPolicy">Privacy Policy</a>&nbsp;and&nbsp;<a
                    @click="toTermsOfService">Terms of Use</a>.
                </template>
              </v-checkbox>

              <div class="elevation-6"
                   style="text-align: center; width: 94%; line-height: 40px; color: #1bacf1;">
                We will contact you by phone or email within 24 hours after your submission.
              </div>

              <div style="width: 50%; display: flow-root; margin: 25px auto">
                <div class="text-center" style="float: left; width: 150px">
                  <v-btn class="text-none" @click="backToLogin">Go Back</v-btn>
                </div>
                <div class="text-center" style="float: right; width: 150px; ">
                  <v-btn class="text-none" @click="submit" color="#39aaba" style="color: white">Submit -></v-btn>
                </div>
              </div>
            </v-container>
          </v-form>

          <div>
            <span style="color: #39aaba;">Contact Customer Support</span>
            <v-container>
              <div style="width: 94%; background-color: white;">
                <div class="pa-2">Call / Text / WhatsApp: +1 650 796 9090</div>
                <div class="pa-2">
                  Email: <a href="mailto:Neo@SstParts.com">Neo@SstParts.com</a>
                </div>
              </div>
            </v-container>
          </div>

          <s-dialog
              v-model="showDialog"
              v-bind="dialogAttrs"
              @close="closeDialog"
              closeButton="Close"
              :hide-confirm="true"
          >
          </s-dialog>

          <s-message :type="messageType" v-model="showMessage">{{
              message || ""
            }}</s-message>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

import {trim, isEmpty} from '@/utils/helper'
import {signUpInfoUrl, getCountryUrl, getStateByCountryCodeUrl, getCityByStateCodeUrl, getCityAndStateByPostalCodeUrl} from "@/api";
import http from "@/http";
import SMessage from "@/components/Message.vue";
import logoUrl from './sstparts.png'
import SDialog from "../../components/Dialog.vue";

export default {
  name: "signUp",
  components: {
    SDialog,
    SMessage,
  },
  data() {
    return {
      forms: {
        company: null,
        industry: null,
        hearFrom: null,
        monthlyOrderAmount: null,
        country: null,
        zip: null,
        city: null,
        state: null,
        website: null,
        otherInfo: null,

        firstName: null,
        lastName: null,
        jobTitle: null,
        email: null,
        phone: null,
        password: null,
        confirmPassword: null,
      },
      agreedToPolicy: false,
      message: "",
      messageType: "error",
      showMessage: false,
      countries: [],
      states: [],
      cities: [],
      logoUrl,
      showDialog: false,
      dialogAttrs: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const $http = await http();
      await $http.get(getCountryUrl).then((res) => {
        if (res.code == 200) {
          this.countries = res.data;
          if (this.countries && this.countries[0]) {
            this.forms.country = this.countries[0].countryCode
            this.toChangeCountry(this.forms.country);
          }
        }
      });
    },
    async toChangeCountry(v) {
      const $http = await http();
      await $http.get(getStateByCountryCodeUrl + v).then((res) => {
        if (res.code == 200) {
          this.states = res.data;
        }
      });
    },
    async toChangeState(v) {
      const $http = await http();
      await $http.get(getCityByStateCodeUrl + v).then((res) => {
        if (res.code == 200) {
          this.cities = res.data.allCity;
        }
      });
    },
    async toChangeZipcode(v) {
      if (!trim(v) || !this.forms.country) return;
      const $http = await http();
      await $http.get(getCityAndStateByPostalCodeUrl + this.forms.country + "/" + v)
          .then((res) => {
            if (res.code == 200) {
              this.cities = res.data.allCity;
              this.forms.state = res.data.state;
              this.forms.city = res.data.city;
            }
          });
    },
    async submit() {
      if (!this.$refs.forms.validate()) return;
      const $http = await http();
      const res = await $http.post(signUpInfoUrl, this.forms, {
        config: {
          headers: {
            isToken: false,
            Authorization: "Basic cXVvdGF0aW9uOnF1b3RhdGlvbg==",
          },
        },
      });

      if (res && res.code === "200") {
        this.dialogAttrs = {
          title: "We will contact you by phone or email within 24 hours after your submission.",
          "max-width": "645px",
          "component-attrs": {},
        };
        this.showDialog = true;
      }

    },
    backToLogin() {
      this.$router.go(-1);
    },
    toPrivacyPolicy() {
      this.$router.push({name: 'privacyPolicy'});
    },
    toTermsOfService() {
      this.$router.push({name: 'termsOfService'});
    },
    notEmpty(val) {
      return isEmpty(val) ? 'This field is required.' : true
    },
    notEmptyIfUs(val) {
      if (this.forms.country === 'US' || this.forms.country === 'CA') {
        return isEmpty(val) ? 'This field is required.' : true
      } else {
        return true
      }
    },
    email(val) {
      const empty = [null, undefined, ""];
      const reg = /^[\w][\w-\.]*@[a-zA-Z0-9][\w-]*(\.[\w-]+){1,}$/i;
      return reg.test(val) || empty.includes(val)
          ? true
          : 'Email is wrong';
    },
    confirmPass(val) {
      return val !== this.forms.password ? 'Password and confirm password must match.' : true
    },
    agreed(val) {
      return val
    },
    closeDialog() {
      this.showDialog = false;
      this.$router.replace({name: 'Login'});
    },
  },
};
</script>
<style scoped>
#bgi {
  width: 100%;
  height: 100%;
  background: url("./background.jpg") center center no-repeat;
  position: fixed;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}
.su-input {
  margin-bottom: 10px;
}
</style>