<template>
  <v-app v-once>
    <div class="ml-5 mt-2"><a @click="backToLogin">← Back</a></div>
    <div style="width: 60%; min-width: 1000px; margin: 0 auto;">
      <h1 class="text-center ma-5">Return Policy</h1>
      <div class="faq-list">
        <ol>
          <li><strong>Parts - <u>Lifetime Warranty</u> (starting from October 2024)</strong>
            <ol>
              <li>All the parts that we sell come with a lifetime warranty.</li>
              <li>Warranty will be voided if parts <span>contain any&nbsp;physical or internal damages</span>.</li>
              <li>All qualified return parts will be credited back to customer's wallet account within 2 weeks.</li>
              <li>We <u>do not exchange parts unless</u>&nbsp;requested by the customer. Highly recommend to place new
                order for parts you still need while they are being returned for wallet credits.
              </li>
              <li>Parts with installation marks or cosmetic flaws (e.g. scratches, smudges or any signs of wear and
                tear) may take up to 3 months to get final inspection result from our aftersales facility.
              </li>
              <li>Warranty will not be eligible if returning a package without a valid RMA #.<br><br><strong>How
                store/wallet credit is calculated:</strong>
                <ol>
                  <li>Items returned within 90 days will receive full credit. Otherwise, a 10% restocking fee will be
                    applied.
                  </li>
                  <li>The (full) credit amount of the return item will be based on the order price paid or the most
                    up-to-date selling price (whichever is lower).<br><br></li>
                </ol>
              </li>
            </ol>
          </li>
          <li><strong>Accessories &amp; Batteries - 90-day warranty<br><br> </strong></li>
          <li><strong>Tools &amp; Machines - 30-day warranty<br><br> </strong></li>
          <li><strong>Return Shipping</strong>
            <ol>
              <li>Customer will be responsible for shipping cost when returning items to us, except on items that were
                incorrectly sent by us.
              </li>
              <li>VIP customers will be qualified for a free return shipping label once per month.<br><br></li>
            </ol>
          </li>
          <li><strong>Special Returns</strong>
            <ol>
              <li><strong>DOA (Dead on Arrival) - Must report within 5 days<br></strong>If the shipment box was dented
                or damaged when you received it, please check all parts immediately. If there are any physical damages,
                please provide us with:<br>Pictures of the front and back of the shipment box.<br>Pictures of the front
                and back of the item that has physical damages.
              </li>
              <li><strong>Customer ordered wrong parts or parts you do not need<br></strong>A 10% restocking fee will be
                charged on items that were mistakenly ordered or is no longer needed.
              </li>
              <li><strong>We sent the wrong parts<br></strong>If you received any incorrect items, please send us
                pictures of both the wrong and correct items if possible (for comparison purposes). Email them along
                with the order # to <a href="mailto:Ca@SstParts.com">Ca@SstParts.com</a> and we will provide a
                free return shipping label in such circumstances.<br><br></li>
            </ol>
          </li>
          <li><strong>Unclaimed return items or packages</strong> will be discarded after storing for a period of three
            months.
          </li>
        </ol>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  name: "returnPolicy",
  components: {},
  data() {
    return {};
  },
  methods: {
    backToLogin() {
      this.$router.go(-1);
    },
  },
};
</script>