var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      {
        style: _vm.checkPhone ? "background-size: 100%" : "",
        attrs: { id: "bgi" },
      },
      [
        _c(
          "v-sheet",
          {
            staticStyle: {
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            },
            style: _vm.checkPhone ? "width: 70%;display: flex" : "width: 90%;",
            attrs: { elevation: "16" },
          },
          [
            _c(
              "div",
              { style: _vm.checkPhone ? "width: 73%" : "" },
              [_c("v-img", { attrs: { cover: "", src: _vm.bannerUrl } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { style: _vm.checkPhone ? "width: 27%" : "" },
              [
                _c(
                  "v-card",
                  {
                    staticStyle: { color: "#39aaba" },
                    attrs: { height: "100%", elevation: "0" },
                  },
                  [
                    _c("v-card-title", { staticStyle: { height: "30%" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#39aaba",
                            "font-size": "1.75em",
                            "font-weight": "bold",
                            margin: "0 auto",
                          },
                        },
                        [_vm._v("\n              LOGIN\n            ")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-card-text",
                      { staticStyle: { height: "70%" } },
                      [
                        _c(
                          "v-form",
                          { ref: "forms", staticStyle: { height: "100%" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { height: "26%" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "text-field-color",
                                  staticStyle: {
                                    width: "75%",
                                    margin: "0 auto",
                                  },
                                  attrs: {
                                    underlined: "false",
                                    "append-icon": "mdi-account",
                                    autocomplete: "new-password",
                                    label: "E-Mail",
                                    rules: [_vm.notEmpty],
                                    color: "#39aaba",
                                  },
                                  model: {
                                    value: _vm.username,
                                    callback: function ($$v) {
                                      _vm.username = $$v
                                    },
                                    expression: "username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { height: "26%" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "text-field-color",
                                  staticStyle: {
                                    width: "75%",
                                    margin: "0 auto",
                                  },
                                  attrs: {
                                    underlined: "false",
                                    "append-icon": "mdi-magnify",
                                    autocomplete: "new-password",
                                    type: "password",
                                    label: "Password",
                                    rules: [_vm.notEmpty],
                                    color: "#39aaba",
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function ($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text-center",
                                style: _vm.checkPhone
                                  ? "height: 22%"
                                  : "height: 60px",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-none",
                                    staticStyle: {
                                      color: "white",
                                      width: "70%",
                                      "font-size": "1.375em",
                                    },
                                    attrs: {
                                      height: "67%",
                                      rounded: "",
                                      color: "#39aaba",
                                    },
                                    on: { click: _vm.handleLogin },
                                  },
                                  [_vm._v("Login")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    color: "#39aaba",
                                    "font-size": "1.5em",
                                    "line-height": "100%",
                                  },
                                  on: { click: _vm.toSignUp },
                                },
                                [_vm._v("Sign Up")]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "s-message",
                  {
                    attrs: { type: "error" },
                    model: {
                      value: _vm.showMessage,
                      callback: function ($$v) {
                        _vm.showMessage = $$v
                      },
                      expression: "showMessage",
                    },
                  },
                  [_vm._v(_vm._s(_vm.message || ""))]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { position: "absolute", left: "3%", bottom: "2%" } },
          [
            _c(
              "span",
              {
                staticClass: "s-primary pointer mr-3",
                on: {
                  click: function ($event) {
                    return _vm.showPolicy("aboutUs")
                  },
                },
              },
              [_vm._v("About Us")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "s-primary pointer mr-3",
                on: {
                  click: function ($event) {
                    return _vm.showPolicy("termsOfService")
                  },
                },
              },
              [_vm._v("Terms of Service")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "s-primary pointer mr-3",
                on: {
                  click: function ($event) {
                    return _vm.showPolicy("privacyPolicy")
                  },
                },
              },
              [_vm._v("Privacy Policy")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "s-primary pointer mr-3",
                on: {
                  click: function ($event) {
                    return _vm.showPolicy("returnPolicy")
                  },
                },
              },
              [_vm._v("Return Policy")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "s-dialog",
          _vm._b(
            {
              attrs: { "hide-actions": true, "hide-confirm": true },
              on: { close: _vm.closeDialog },
              model: {
                value: _vm.showDialog,
                callback: function ($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog",
              },
            },
            "s-dialog",
            _vm.dialogAttrs,
            false
          ),
          [
            _c("div", { staticClass: "pa-2" }, [
              _vm._v("Call / Text / WhatsApp: +1 650 796 9090"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pa-2" }, [
              _vm._v("Email: "),
              _c("a", { attrs: { href: "mailto:Neo@SstParts.com" } }, [
                _vm._v("Neo@SstParts.com"),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }