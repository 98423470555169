var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("v-app", [
      _c("div", { staticClass: "ml-5 mt-2" }, [
        _c("a", { on: { click: _vm.backToLogin } }, [_vm._v("← Back")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            width: "60%",
            "min-width": "1010px",
            margin: "0 auto",
          },
        },
        [
          _c("h1", { staticClass: "text-center ma-5" }, [_vm._v("About Us")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "articleWrap contactPage" },
            [
              _c("p", { staticClass: "text" }, [
                _c("b", [_vm._v("SstParts.com")]),
                _vm._v(
                  " is an IT driven e-Commerce company. We\n      offer the most competitive prices, industry standard quality, same day shipping, and a large selection of products.\n      Our industry's 1st mobile apps bring you a whole new shopping experience with incredible features and deals!"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "We have a responsibility to our customers, and we have a commitment to grow and evolve. With years of\n        experience and success in the cell phone parts industry, we here at SST are regularly current on all of the latest\n        industry mobile devices. We aim to offer our customers an extensive variety of the latest cell phone and tablet\n        parts and more."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "Our selection of products cannot be matched as we carry over "
                ),
                _c("b", [_vm._v("73,000 different parts")]),
                _vm._v(
                  ". We not\n        only carry parts for the newest mobile phone out, but we also carry parts for older devices that are difficult to\n        find elsewhere. We are also proud to say we have "
                ),
                _c("b", [
                  _vm._v("the most competitive prices in the entire industry"),
                ]),
                _vm._v("."),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "SST provides shipping throughout most of the world. That is why we provide "
                ),
                _c("b", [_vm._v("multiple shipping\n        locations")]),
                _vm._v(
                  " in California, USA and Hong Kong. Our international customers can feel at ease when selecting where\n        they want their orders to be shipped from (depending on where you are located, you may select the closest warehouse\n        that is convenient for you). We also provide "
                ),
                _c("b", [_vm._v("free next day shipping")]),
                _vm._v(
                  " for specific qualified orders. Please\n        refer to the Payment & Delivery section for more information."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v("All of our products come with a "),
                _c("b", [_vm._v("90-day warranty")]),
                _vm._v(" and "),
                _c("b", [_vm._v("life time spare parts warranty")]),
                _vm._v(", as\n        long as the customer complies with our "),
                _c("a", { on: { click: _vm.toReturnPolicy } }, [
                  _vm._v("return policy"),
                ]),
                _vm._v(
                  ". We have\n        incorporated an all new easy-to-use RMA system that will make returns much simpler with less effort from the\n        customer's end."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "We've come a long way, so we know exactly which direction to take when supplying our customers with\n        high quality yet exceptional products. We offer all of this while providing excellent customer service and friendly\n        support. Our growth is predicated on customers' success, customers' interests and satisfactions will always be the\n        top priority for us."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text" }, [
                _vm._v(
                  "In addition, our creative teams' missions are building software applications (web and mobile) to help\n        our small business customers "
                ),
                _c("b", [_vm._v("run their operations better and cut costs")]),
                _vm._v(
                  " (including account, pricing,\n        inventory, return and repair tracking management tools)."
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { "min-height": "180px" } },
                        [
                          _c("v-card-title", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": ".875rem",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                  width: "100%",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                The most competitive prices worldwide\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(
                                "We offer the best prices in the entire industry and a large selection of products."
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { "min-height": "180px" } },
                        [
                          _c("v-card-title", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": ".875rem",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                  width: "100%",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Same day shipping\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(
                                "Our order fulfillment teams around the globe will process your order as quickly as possible."
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { "min-height": "180px" } },
                        [
                          _c("v-card-title", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": ".875rem",
                                  "text-align": "center",
                                  "font-weight": "bold",
                                  width: "100%",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Our professionals at your service\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-card-text", [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v("Mon-Fri: 7am to 9:00pm PT "),
                              _c("br"),
                              _vm._v("Saturday: 10am to 6:30pm PT "),
                              _c("br"),
                              _vm._v(
                                "Call / Text / WhatsApp: +1 650 796 9090 "
                              ),
                              _c("br"),
                              _vm._v("Email: "),
                              _c(
                                "a",
                                { attrs: { href: "mailto:Neo@SstParts.com" } },
                                [_vm._v("Neo@SstParts.com")]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }