var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("v-app", [
      _c("div", { staticClass: "ml-5 mt-2" }, [
        _c("a", { on: { click: _vm.backToLogin } }, [_vm._v("← Back")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            width: "60%",
            "min-width": "1000px",
            margin: "0 auto",
          },
        },
        [
          _c("h1", { staticClass: "text-center ma-5" }, [
            _vm._v("Terms of Service"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "faq-list" }, [
            _c("p", [
              _c("span", [
                _vm._v(
                  "Disclaimer: All trademarks are properties of their respective holders. SstParts.com is a third party replacement parts wholesaler and does not own or make claim to those trademarks used on this website in which it is not the holder"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("br"),
              _c("span", [
                _vm._v(
                  ' You may use the SstParts.com web site (the "Site"); the services provided therethrough, content, technologies and applications of '
                ),
                _c("u", [_vm._v("EuropaX")]),
                _vm._v(
                  ', a California corporation (the "Company", "we" or "us") only on the condition that you abide by the following terms.'
                ),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  ' BY USING THE SITE AND/OR BY REGISTERING TO OPEN AN ACCOUNT WITH US OR USING GUEST CHECKOUT, YOU SIGNIFY YOUR AGREEMENT TO THE FOLLOWING TERMS ("SITE TERMS"):'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [
              _c("strong", [_c("span", [_vm._v("About The Site Terms")])]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  'The Site is a service made available by the Company and its affiliates. The Site Terms have the same effect as an agreement in writing and govern your use of the Site. If you do not agree to the Site Terms, please do not use the Site. We may modify the Site Terms at any time. If you do not agree to the changes, you may discontinue using the Site and the services provided therethrough before the changes take effect. Your continued use of the Site after any such changes take effect constitutes your acceptance to such changes. Each time you visit or log into the Site, you reaffirm your acceptance of the Site Terms. You are responsible for regularly reviewing the Site Terms, by clicking on the "Terms of Service" link located at the bottom of the first page of the Site. The Site Terms may be supplemented by additional terms and conditions applicable to privacy, specific areas of this Site, or to particular content or transactions are posted in particular areas of the site and, together with these Site Terms, govern your use of those areas, content, or transactions.'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("About the Site")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  'The Site gives users the ability to buy replacement parts and repair certain cellular phone, smartphone and mobile tablet devices (collectively, the "Product") subject to these Site Terms. We may, in our sole discretion, change or discontinue any or all aspects of the Site at any time, without notice, and without liability to you.'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("span", [_c("strong", [_vm._v("Registration")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "Registration is not required to visit the Site; however, you will need to create an account if you desire to use all of the Site's services and features or to complete a transaction with us. If you register to open an account, you agree to provide accurate, complete registration information. You must be legally capable to enter into contracts. It is your responsibility to make any updates to that information. Each registration is for a single person only, unless specifically designated otherwise on the registration page. We do not permit any other person using the registered sections under your name. You are responsible for preventing such unauthorized use. If you believe there has been unauthorized use, you should notify the Company immediately by emailing the Company at Ca@SstParts.com"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Ownership")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  'The Site contains information, content or advertisements text, photographs, designs, graphics, images, sound and video recordings, animation and other materials and effects (collectively, the "Content") that are protected by copyrights, trademarks, service marks, trade dress, patents or other intellectual or proprietary rights owned by the Company or other third parties. All trademarks and copyrighted information contained on the Site are the property of their respective owners. You may not remove any electronic or printed copy any copyright, trademark, or other proprietary notice.'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [
              _c("strong", [_c("span", [_vm._v("Use of Site and Content")])]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "The Company provides the Site and the Content for your individual, non-commercial use and solely for the purpose of carrying out individual transactions with the Site. Any other use of the Site or the Content is strictly prohibited. You may not, either directly or indirectly:"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Modify, republish, redistribute, delete, resell, sublicense, publicly perform, cache by proxy the Site or the Content without the express written permission of the Company or the applicable rights holder;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Use the Site or the Content for telemarketing, direct marketing, and commercial mass e-mail or by agents or representatives or e-mail spammers;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Reverse engineer, decompile, disassemble, merge, copy, use, disclose, rent, lease, loan, sell, sublicense or transfer the underlying source code or structure or sequence of the Site or delete or alter author attributes or copyright notices;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Use any network monitoring or discovery software to determine the Site architecture, or extract information about usage or users;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Reformat or frame any portion of the Site or the Content;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Use any device, software or routine that interferes with the proper working of the Site, or otherwise attempt to interfere with the proper working of the Site;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Allow others to use the Site under or through your account login ID/email and password;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Take any action that imposes, or may impose in our sole discretion an unreasonable or disproportionately large load on our infrastructure;"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(
                    "Violate these Site Terms, applicable law or the rights of others; or disrupt or interfere with the security of, or otherwise cause harm to the Site."
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("br"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "Any permitted use of the Site does not extend to using the Site or the Content for any illegal purpose, or to transmit to or through the Site or through any service any illegal, harmful, threatening, defamatory, obscene, hateful, pornographic or other objectionable material of any kind, or to interfere with, abuse or otherwise violate the legal rights of any third party using the Site or the Content."
                ),
              ]),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _c("span", [
                _vm._v(
                  " Without limiting other remedies, we may limit, suspend, or terminate our service and user accounts, prohibit access to the Site, remove hosted content, and take technical and legal steps to keep users off the Sites if we think that they are creating problems, possible legal liabilities, or acting inconsistently with the letter or spirit of our policies. We also reserve the right to cancel unconfirmed accounts."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("License")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "By using the Site, uploading content to or submitting any materials for use on the Site, you grant (or warrant that the owner of such rights has expressly granted) the Company a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such materials and/or information generated through use of the Site or incorporate such materials and/or information generated through use of the Site into any form, medium, or technology now known or later developed throughout the universe. We need these rights to host and display your content."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Linking")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "You may provide links only to the homepage of this Site, provided (a) you do not remove or obscure, by framing or otherwise, any portion of the homepage, (b) you give the Company notice of such link by sending an e-mail to Order@SstParts.com and (c) you discontinue providing links to this Site if requested by the Company. The Company will notify you if, within its sole and unfettered discretion, permission is granted, and, if so, the terms and conditions of the permission."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Representations")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "You represent and warrant to us that (a) you are legally capable to enter into contracts, (b) you are providing us at all times true, accurate and up to date information about yourself, (c) you will comply at all times with the Site Terms and applicable law and (d) your use of the Site and any transactions that you make with us will not violate the rights of any third party."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Disclaimer")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "The Company makes every effort to ensure the information presented in, on or through its Site is accurate, however, because the Company communicates information provided and created by advertisers, software developers, publishers, marketing agents, resellers and other third parties, the Company has no control over the accuracy of such information, and makes no guarantee as to such information, and is not responsible for the information, including its accuracy, currency, content, quality, copyright compliance or legality, or any resulting loss or damage."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [
              _c("strong", [
                _c("span", [_vm._v("Warranty, Liability, Indemnification")]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "The Company makes no representations regarding the availability and performance of its Site. You hereby acknowledge that any use of the Site and reliance upon any of the content shall be at your sole risk and that the Company shall not be liable for any loss of data, lost profits or any other damages or losses resulting from such use."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  'THE SITE AND THE CONTENT ARE PROVIDED BY THE COMPANY ON AN "AS IS" BASIS AND AS AVAILABLE, WITHOUT ANY WARRANTY OR REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE. THE COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND THE IMPLIED CONDITIONS OF SATISFACTORY QUALITY AND ACCEPTANCE AS WELL AS ANY LOCAL JURISDICTIONAL ANALOGUES TO THE ABOVE AND OTHER IMPLIED OR STATUTORY WARRANTIES.'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "IN NO EVENT SHALL THE COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS OR CONTRACTORS BE LIABLE FOR ANY COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOSS OF DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY CLAIMS OF YOU OR OTHER THIRD PARTIES WHATSOEVER WITH RESPECT TO THE SITE OR THE CONTENT REGARDLESS OF THE LEGAL THEORY ON WHICH THE CLAIM IS BASED, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES THAT RESULT FROM ANY MISTAKE, OMISSION, VIRUS, DELAY OR INTERRUPTION IN OPERATION OR SERVICE OR FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM AN ACT OF GOD, COMMUNICATIONS FAILURE, THEFT OR OTHERWISE. THE COMPANY SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES WHATSOEVER RESULTING FROM ANY FAILURE OF THE INTERNET."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "Some states do not allow the exclusion or limitation of liability of consequential or incidental damages, so the above exclusions may not apply to all users; in such states liability is limited to the fullest extent permitted by law."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  'THE COMPANY DOES NOT GUARANTEE THAT ANY CONTENT, INFORMATION, SOFTWARE OR OTHER MATERIAL ACCESSIBLE THROUGH THE SITE WILL BE FREE OF VIRUSES, "WORMS," "TROJAN HORSES," OR THEIR HARMFUL COMPONENTS. THE COMPANY\'S ENTIRE LIABILITY AND YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO THE USE OF ANY SERVICES PROVIDED BY THE COMPANY SHALL BE CANCELLATION OF YOUR ACCOUNT.'
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Indemnity")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "You agree to indemnify, defend, and hold harmless the Company, its parent, subsidiaries, affiliates, officers, directors, employees, agents, representatives, vendors and distributors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your (or anyone using your account's) violation or breach of any representation or obligation under these Site Terms. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with our defense of such claim."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Privacy")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "We view protection of users' privacy as a very important community principle. When you open an account and use the Site, we collect information about you. You agree that we may collect and use your information in accordance with the Company's Privacy Policy, which you can review by going to "
                ),
                _c("a", { on: { click: _vm.toPrivacyPolicy } }, [
                  _c("strong", [_vm._v("Privacy Policy")]),
                ]),
                _vm._v(
                  " page. If you object to your information being transferred or used in this way please do not use our services."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [
              _c("strong", [
                _c("span", [_vm._v("Electronic Notices and Transactions")]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "You agree to transact with us electronically. This means you agree to accept and terms and conditions and to transact any sale, recycling or repair of your product with us by electronic means. You authorize us to send you important notices about the Site and any pending transactions to an email address you provide to us, if you are a registered account holder or, in the alternative, by posting a notice on the Site. It is your duty to keep your email address up to date and to maintain a valid email address and to ensure that emails we send you are not filtered or stopped by spam filters or other types of email blocking functionalities. If you no longer desire to transact electronically with us, you may no longer use the Site. You can retrieve and review these Site Terms at any time by visiting "
                ),
                _c("strong", [_vm._v("Terms of Service")]),
                _vm._v(" page."),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Access")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "You are responsible for obtaining at your own expense all equipment and services needed to access and use the Site, including all devices, Internet browsers and Internet access. If you access the Site or a Site application through a mobile or wireless device, you are responsible for all fees that your carrier may charge you for data, text messaging and other wireless access or communications services."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("h2", [_c("strong", [_c("span", [_vm._v("Miscellaneous")])])]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "The Company reserves the right to investigate complaints or reported violations of the Site Terms and to take any action the Company deems appropriate including but not limited to reporting any suspected unlawful activity to law enforcement officials, regulators, or other third parties and disclosing any information necessary or appropriate to such persons or entities relating to user profiles, e- mail addresses, usage history, posted materials, IP addresses and traffic information. The Company reserves the right to seek all remedies available at law and in equity for violations of these Site Terms, including but not limited to the right to block access from a particular Internet address to the Site. These incorporate by reference any notices contained on the Site and constitutes the entire agreement with respect to access to and use of the Site. If any provision of these Site Terms is unlawful, void or unenforceable then that provision shall be deemed severable from the remaining provisions and shall not affect their validity and enforceability. THESE SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF CALIFORNIA, USA, AS THEY APPLY TO AGREEMENTS MADE AND SOLELY PERFORMED THEREIN. YOU IRREVOCABLY CONSENT AND WAIVE ALL OBJECTION TO PERSONAL JURISDICTION AND VENUE IN THE STATE AND FEDERAL COURTS LOCATED IN CITY AND COUNTY OF SAN FRANCISCO, CALIFORNIA, USA, AND YOU SHALL NOT COMMENCE OR PROSECUTE ANY SUIT OR ACTION EXCEPT IN THE FOREGOING COURTS."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
            _vm._v(" "),
            _c("p", [_c("span", [_vm._v("Last Updated: October 01, 2024")])]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }