var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c("div", { attrs: { id: "bgi" } }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "56%",
            "min-width": "1050px",
            margin: "0 auto",
            "font-weight": "500",
            padding: "0 30px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center ma-3",
              staticStyle: {
                color: "#39aaba",
                "font-size": "1.5em",
                "font-weight": "600",
              },
            },
            [
              _c("div", [
                _c("img", {
                  staticClass: "pointer",
                  staticStyle: { width: "200px" },
                  attrs: { src: _vm.logoUrl },
                  on: { click: _vm.backToLogin },
                }),
              ]),
              _vm._v("\n        Create an Account\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-form",
                { ref: "forms", staticStyle: { height: "100%" } },
                [
                  _c("span", { staticStyle: { color: "#39aaba" } }, [
                    _vm._v("Organization Information"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c("span", { staticStyle: { color: "#FF5252" } }, [
                        _vm._v("* "),
                      ]),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("Business Name"),
                      ]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        staticStyle: { width: "400px" },
                        attrs: {
                          rules: [_vm.notEmpty],
                          clearable: "",
                          outlined: "",
                          dense: "",
                          "hide-details": "true",
                        },
                        model: {
                          value: _vm.forms.company,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "company", $$v)
                          },
                          expression: "forms.company",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#FF5252" } }, [
                        _vm._v("* "),
                      ]),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("Industry"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            row: "",
                            rules: [_vm.notEmpty],
                            "hide-details": "true",
                          },
                          model: {
                            value: _vm.forms.industry,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "industry", $$v)
                            },
                            expression: "forms.industry",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "Repair Service (Shop)",
                              value: "Repair Service (Shop)",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "Drop Shiper",
                              value: "Drop Shiper",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "Repair Center / Refurbisher",
                              value: "Repair Center / Refurbisher",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "Parts Reseller",
                              value: "Parts Reseller",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Others", value: "Others" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#FF5252" } }, [
                        _vm._v("* "),
                      ]),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("How did you hear about us?"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            row: "",
                            rules: [_vm.notEmpty],
                            "hide-details": "true",
                          },
                          model: {
                            value: _vm.forms.hearFrom,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "hearFrom", $$v)
                            },
                            expression: "forms.hearFrom",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Google", value: "Google" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "Referred by friends",
                              value: "Referred by friends",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Forum", value: "Forum" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Youtube", value: "Youtube" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Facebook", value: "Facebook" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Instagram", value: "Instagram" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Tiktok", value: "Tiktok" },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: { label: "Others", value: "Others" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "#FF5252" } }, [
                        _vm._v("* "),
                      ]),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("Estimated Monthly Order (From All Sources)"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mb-2",
                          staticStyle: { "margin-top": "0" },
                          attrs: {
                            row: "",
                            rules: [_vm.notEmpty],
                            "hide-details": "true",
                          },
                          model: {
                            value: _vm.forms.monthlyOrderAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "monthlyOrderAmount", $$v)
                            },
                            expression: "forms.monthlyOrderAmount",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "Up to $3,000",
                              value: "Up to $3,000",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "$3,001 to $5,000",
                              value: "$3,001 to $5,000",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "$5,001 to $10,000",
                              value: "$5,001 to $10,000",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "$10,001 to $30,000",
                              value: "$10,001 to $30,000",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "More than $30,001",
                              value: "More than $30,001",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "22%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Country"),
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              staticClass: "mb-2",
                              attrs: {
                                items: _vm.countries,
                                rules: [_vm.notEmpty],
                                "item-text": "countryName",
                                "item-value": "countryCode",
                                dense: "",
                                outlined: "",
                                "hide-details": "true",
                              },
                              on: { change: _vm.toChangeCountry },
                              model: {
                                value: _vm.forms.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "country", $$v)
                                },
                                expression: "forms.country",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "22%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _vm.forms.country === "US" ||
                            _vm.forms.country === "CA"
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#FF5252" } },
                                  [_vm._v("* ")]
                                )
                              : _vm._e(),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Postal / Zip Code"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmptyIfUs],
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              on: { change: _vm.toChangeZipcode },
                              model: {
                                value: _vm.forms.zip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "zip", $$v)
                                },
                                expression: "forms.zip",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "22%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("City"),
                            ]),
                            _vm._v(" "),
                            _c("v-combobox", {
                              staticClass: "mb-2",
                              attrs: {
                                items: _vm.cities,
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "city", $$v)
                                },
                                expression: "forms.city",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "block", width: "22%" } },
                          [
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("State"),
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              staticClass: "mb-2",
                              attrs: {
                                items: _vm.states,
                                "item-text": "stateName",
                                "item-value": "stateCode",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              on: { change: _vm.toChangeState },
                              model: {
                                value: _vm.forms.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "state", $$v)
                                },
                                expression: "forms.state",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("Website"),
                      ]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        staticStyle: { width: "94%" },
                        attrs: {
                          clearable: "",
                          outlined: "",
                          dense: "",
                          "hide-details": "true",
                        },
                        model: {
                          value: _vm.forms.website,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "website", $$v)
                          },
                          expression: "forms.website",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { color: "grey" } }, [
                        _vm._v("Anything else you would like us to know?"),
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mb-2",
                        staticStyle: { width: "94%" },
                        attrs: {
                          rows: "1",
                          "no-resize": "",
                          clearable: "",
                          outlined: "",
                          "hide-details": "true",
                        },
                        model: {
                          value: _vm.forms.otherInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "otherInfo", $$v)
                          },
                          expression: "forms.otherInfo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticStyle: { color: "#39aaba" } }, [
                    _vm._v("User Information"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "30%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("First Name"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty],
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.firstName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "firstName", $$v)
                                },
                                expression: "forms.firstName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "30%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Last Name"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty],
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.lastName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "lastName", $$v)
                                },
                                expression: "forms.lastName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "block", width: "30%" } },
                          [
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Job Title"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.jobTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "jobTitle", $$v)
                                },
                                expression: "forms.jobTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "46%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v(
                                "Email (this email will be your login name)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty, _vm.email],
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "email", $$v)
                                },
                                expression: "forms.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "block", width: "46%" } },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Telephone"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty],
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "phone", $$v)
                                },
                                expression: "forms.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "block",
                              width: "32%",
                              "margin-right": "2%",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Password"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty],
                                type: "password",
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "password", $$v)
                                },
                                expression: "forms.password",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { display: "block", width: "32%" } },
                          [
                            _c("span", { staticStyle: { color: "#FF5252" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", { staticStyle: { color: "grey" } }, [
                              _vm._v("Confirm Password"),
                            ]),
                            _vm._v(" "),
                            _c("v-text-field", {
                              staticClass: "mb-2",
                              attrs: {
                                rules: [_vm.notEmpty, _vm.confirmPass],
                                type: "password",
                                clearable: "",
                                outlined: "",
                                dense: "",
                                "hide-details": "true",
                              },
                              model: {
                                value: _vm.forms.confirmPassword,
                                callback: function ($$v) {
                                  _vm.$set(_vm.forms, "confirmPassword", $$v)
                                },
                                expression: "forms.confirmPassword",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-checkbox", {
                        attrs: { rules: [_vm.agreed] },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                By creating an account, you agree to SstParts' "
                                ),
                                _c(
                                  "a",
                                  { on: { click: _vm.toPrivacyPolicy } },
                                  [_vm._v("Privacy Policy")]
                                ),
                                _vm._v(" and "),
                                _c(
                                  "a",
                                  { on: { click: _vm.toTermsOfService } },
                                  [_vm._v("Terms of Use")]
                                ),
                                _vm._v(".\n              "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.agreedToPolicy,
                          callback: function ($$v) {
                            _vm.agreedToPolicy = $$v
                          },
                          expression: "agreedToPolicy",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "elevation-6",
                          staticStyle: {
                            "text-align": "center",
                            width: "94%",
                            "line-height": "40px",
                            color: "#1bacf1",
                          },
                        },
                        [
                          _vm._v(
                            "\n              We will contact you by phone or email within 24 hours after your submission.\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "50%",
                            display: "flow-root",
                            margin: "25px auto",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { float: "left", width: "150px" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  on: { click: _vm.backToLogin },
                                },
                                [_vm._v("Go Back")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-center",
                              staticStyle: { float: "right", width: "150px" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  staticStyle: { color: "white" },
                                  attrs: { color: "#39aaba" },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v("Submit ->")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("span", { staticStyle: { color: "#39aaba" } }, [
                    _vm._v("Contact Customer Support"),
                  ]),
                  _vm._v(" "),
                  _c("v-container", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "94%",
                          "background-color": "white",
                        },
                      },
                      [
                        _c("div", { staticClass: "pa-2" }, [
                          _vm._v("Call / Text / WhatsApp: +1 650 796 9090"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pa-2" }, [
                          _vm._v("\n                Email: "),
                          _c(
                            "a",
                            { attrs: { href: "mailto:Neo@SstParts.com" } },
                            [_vm._v("Neo@SstParts.com")]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "s-dialog",
                _vm._b(
                  {
                    attrs: { closeButton: "Close", "hide-confirm": true },
                    on: { close: _vm.closeDialog },
                    model: {
                      value: _vm.showDialog,
                      callback: function ($$v) {
                        _vm.showDialog = $$v
                      },
                      expression: "showDialog",
                    },
                  },
                  "s-dialog",
                  _vm.dialogAttrs,
                  false
                )
              ),
              _vm._v(" "),
              _c(
                "s-message",
                {
                  attrs: { type: _vm.messageType },
                  model: {
                    value: _vm.showMessage,
                    callback: function ($$v) {
                      _vm.showMessage = $$v
                    },
                    expression: "showMessage",
                  },
                },
                [_vm._v(_vm._s(_vm.message || ""))]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }